import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { handleEnterAndSpaceKeyUp } from '../../services/accessibility';
import styles from './mobile-cta-button.scss';
import { withPreviewClickBlocker } from '../../../common/components/click-blocker/with-preview-click-blocker';

const MobileCTAButton = ({
  onClick,
  contentFontClassName,
  label,
  dataHook,
  Icon,
  isSecondary,
  noPadding,
  containerClassNames,
  preventClickInPreview,
}) => {
  const className = classNames(
    styles.button,
    contentFontClassName,
    isSecondary ? 'button-border-color' : 'button-background-color',
    isSecondary ? 'button-color' : 'button-primary-text-color',
    isSecondary ? 'button-fill' : 'icon-fill',
    { [styles.isSecondary]: isSecondary },
  );
  return (
    <div
      className={classNames(
        styles.container,
        containerClassNames,
        noPadding ? styles.noPadding : null,
      )}
    >
      <div
        className={className}
        onClick={preventClickInPreview(onClick)}
        tabIndex="0"
        onKeyUp={handleEnterAndSpaceKeyUp(preventClickInPreview(onClick))}
        data-hook={dataHook}
        role="button"
        label={label}
      >
        {Icon && <Icon className={styles.icon} />}
        {label}
      </div>
    </div>
  );
};

MobileCTAButton.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  contentFontClassName: PropTypes.string.isRequired,
  dataHook: PropTypes.string,
  icon: PropTypes.element,
  isSecondary: PropTypes.bool,
  noPadding: PropTypes.bool,
  preventClickInPreview: PropTypes.func,
};

export default flowRight(withFontClassName, withPreviewClickBlocker)(MobileCTAButton);

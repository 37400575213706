import PropTypes from 'prop-types';
import React from 'react';
import MobileCtaButton from '../mobile-cta-button';
import { useTranslation } from '@wix/yoshi-flow-editor';

const CreatePostCard = ({ onClick, showQuestionLabel, createPostCtaLabel, noPadding }) => {
  const { t } = useTranslation();

  const label = createPostCtaLabel
    ? createPostCtaLabel
    : showQuestionLabel
    ? t('create-post-card.create-new-question')
    : t('create-post-card.create-post');

  return (
    <MobileCtaButton
      onClick={onClick}
      label={label}
      dataHook="create-post-cta"
      noPadding={noPadding}
    />
  );
};

CreatePostCard.propTypes = {
  onClick: PropTypes.func,
  createPostCtaLabel: PropTypes.string,
  showQuestionLabel: PropTypes.bool,
};

CreatePostCard.defaultProps = {
  showQuestionLabel: false,
};

export default CreatePostCard;

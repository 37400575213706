import React from 'react';

import PostActions from '../../containers/post-actions';
import { MODAL_TYPE_POST_ACTIONS } from '../modals/action-sheet/action-sheet-modal-types';
import MoreButton from '../more-button';
import MoreButtonMobile from '../more-button-mobile';
import { Themes } from '../../constants/themes';

const MoreButtonUniversalPost = ({ post, origin, isMobile, className }) => {
  return (
    <div className={className}>
      {isMobile ? (
        <MoreButtonMobile
          type={MODAL_TYPE_POST_ACTIONS}
          post={post}
          focusOnCloseId={`more-button-${post._id}`}
          origin={origin}
        />
      ) : (
        <MoreButton id={`more-button-${post._id}`} theme={Themes.BlackAndWhite}>
          <PostActions
            post={post}
            focusOnCloseId={`more-button-${post._id}`}
            origin={origin}
            theme={Themes.BlackAndWhite}
          />
        </MoreButton>
      )}
    </div>
  );
};

export default MoreButtonUniversalPost;
